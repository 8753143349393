<template>
  <DefaultLayout> </DefaultLayout>
</template>

<script setup>
import DefaultLayout from './layouts/DefaultLayout.vue'
import { RouterView } from 'vue-router'
import DefaultLayoutVue from './layouts/DefaultLayout.vue'
import { onBeforeMount, onMounted } from 'vue'
import { useUserStore } from '../src/stores/store.js'
import { useRouter } from 'vue-router'
import { getCountries } from './API/API'

const router = useRouter()

const userStore = useUserStore()
onBeforeMount(async () => {
  await getCountries()
})
</script>

<style scoped>
@media (min-width: 1024px) {
}
</style>
