import './assets/main.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import './index.css'
import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue";
import mixpanel from 'mixpanel-browser';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import 'primeicons/primeicons.css'
import Lara from '@/presets/lara'; 

mixpanel.init('bfaa3bd29b348c14f8ac3eac8bfb2a90', { debug: false, track_pageview: true, persistence: 'localStorage' });
const app = createApp(App)
Sentry.init({
    app,
    dsn: "https://67220d843efc12003b4a39a9d03c0049@o4505438942724096.ingest.us.sentry.io/4506780730392576",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/alpha.creatortesting\.com/, /^https:\/\/dev2.creatortesting\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// app.use(vuetify)
app.use(PrimeVue, {
    unstyled: true,
    pt: Lara,
    ripple: true
});
app.use(ToastService);
app.use(createPinia());
app.use(router);

app.mount('#app')
