export async function loadLayoutMiddleware(to, from, next) {
    console.log("to:", to, "type:", to.meta.type)
    let userStore;
    let { useUserStore } = await import(`../../stores/store.js`)
    userStore = useUserStore();
    let route = to;
    try {
        let layout = route.meta.layout
        console.log(route.meta)
        if (!layout) {
            let layoutComponent = await import(`../../layouts/AppLayout.vue`)
            route.meta.layoutComponent = layoutComponent.default
        } else {
            let layoutComponent = await import(`../../layouts/${layout}.vue`)
            route.meta.layoutComponent = layoutComponent.default
        }
        // checking user type and fetching data
        let api = await import(`../../API/API.js`)
        let response;
        if (!userStore?.user?.type) {
            switch (route.meta.type) {
                case 'public':
                    next()
                    break;
                case 'researcher':
                    response = await api.getResearchersInfo()
                    if (response) {
                        if (response.type == "researcher") {

                            if (!response?.organisation_info && response.type == "researcher") {
                                next({ name: 'GettingStarted' })
                            } else {
                                next()
                            }
                        } else if (response.type == "admin") {
                            next({ name: 'AdminDashboard' })
                        } else {
                            next({ name: 'creatorLogin' })
                        }
                    } else {
                        next({ name: 'researcherLogin' })
                    }
                    break;
                case 'researcher_onboarding':
                    response = await api.getResearchersInfo()
                    if (response) {
                        if (response.type == "researcher") {
                            if (response?.organisation_info && response.type == "researcher") {
                                next({ name: 'AllResearches' })
                            } else {
                                next()
                            }
                        } else if (response.type == "admin") {
                            next({ name: 'AdminDashboard' })
                        } else {
                            next({ name: 'creatorLogin' })
                        }
                    } else {
                        next({ name: 'researcherLogin' })
                    }
                    break;
                case 'researcherLogin':
                    response = await api.getResearchersInfo()
                    if (response) {
                        if (response.type == "researcher") {
                            if (!response.organisation_info) {
                                next({ name: 'GettingStarted' })
                            } else {
                                next({ name: 'AllResearches' })
                            }
                        } else if (response.type == "admin") {
                            next({ name: 'AdminDashboard' })
                        } else {
                            next({ name: 'creatorLogin' })
                        }
                    } else {
                        next()
                    }

                    break;
                case 'creator':
                    response = await api.getCreatorInfo()
                    if (response) {
                        if (response.type == "creator") {

                            if (!response.dob) {
                                next({ name: 'CreatorInfo' })
                            } else {
                                next()
                            }
                        } else {
                            next({ name: 'researcherLogin' })
                        }
                    } else {
                        next({ name: 'creatorLogin' })
                    }
                    break;
                case 'creatorLogin':
                    response = await api.getCreatorInfo()
                    if (response) {
                        if (response.type == "creator") {
                            if (!response.dob && response.type == "creator") {
                                next({ name: 'CreatorInfo' })
                            } else {
                                next({ name: 'CreatorHome' })
                            }
                        } else {
                            next({ name: 'researcherLogin' })
                        }
                    } else {
                        next()
                    }
                    break;
                case 'creator_unauthorised':
                    next()
                    response = await api.getCreatorInfo()
                    break;
                case 'researcher_unauthorised':
                    next()
                    response = await api.getResearchersInfo()
                    break;
                // admin 
                case 'admin':
                    let userInfo = await api.getResearchersInfo()
                    if (userInfo.type == 'admin') {
                        next()
                    } else {
                        next({ name: 'researcherLogin' })
                    }
                    break;
                default:
                    next()
                    break;
            }
        } else {
            switch (route.meta.type) {
                case 'researcherLogin':
                    response = userStore.user
                    if (response.type == "researcher") {
                        if (!response.organisation_info) {
                            next({ name: 'GettingStarted' })
                        } else {
                            next({ name: 'AllResearches' })
                        }
                    } else {
                        next()
                    }
                    break;
                case 'creatorLogin':
                    response = userStore.user
                    if (response.type == "creator") {
                        if (!response.dob) {
                            next({ name: 'CreatorInfo' })
                        } else {
                            next({ name: 'CreatorHome' })
                        }
                    } else {
                        next()
                    }
                    break;
                case 'creator_unauthorised':
                    if (userStore.user.type != "creator") {
                        userStore.user = {}
                    }
                    next()
                    break;
                default:
                    next()
            }
        }
    } catch (e) {
        console.error('Error occurred in processing of layouts: ', e)
        console.log('Mounted default layout DefaultLayout')
    }
}
