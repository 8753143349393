import { ref, computed } from 'vue'
import mixpanel from 'mixpanel-browser';
import { defineStore } from 'pinia'
import * as _ from 'lodash';



export const useUserStore = defineStore('user', {
  state: () => {
    return {
      user: {},
    }
  },
  getters: {
    getUserFirstName: (state) => state.user?.name?.split(' ')[0],
  },
  actions: {
    saveUserInfo(user) {
      this.user = { ...user }
      if (this.user?.id) {
        mixpanel.identify(this.user.id)
      }
    }
  }
})

export const useCountryStore = defineStore('countries', {
  state: () => {
    return {
      countries: {},
    }
  },
  actions: {
    saveCountries(info) {
      this.countries = { ...info }

    },
    getCountryName(country) {
      let temp_countries = _.cloneDeep(this.countries)
      temp_countries.countries[temp_countries.current.code] = temp_countries.current
      if (temp_countries?.countries && temp_countries?.countries[country]) {
        return temp_countries.countries[country].emoji + ' ' + temp_countries.countries[country].name
      } else {
        return country
      }
    }
  }
})

export const useSurveyStore = defineStore('surveys', {
  state: () => {
    return {
      allSurveys: [],
      fetchingSurveys: false,
      filterdSurveys: []
    }
  },
  getters: {},
  actions: {
    saveSurveys(surveys) {
      this.allSurveys = surveys
    },
    loadingSurveys(flag) {
      this.fetchingSurveys = flag
    },
    saveFilterdSurvey(surveys) {
      this.filterdSurveys = surveys
    }
  }
})

export const useCreateSurveyStore = defineStore('addEditSurveys', {
  state: () => {
    return {
      surveyDetails: {},
      surveyForm: {
        questions: []
      },
      activeSurveyFormIndex: 0,
    }
  },
  actions: {
    saveSurveyDetails(surveyInfos) {
      this.surveyDetails = { ...this.surveyDetails, ...surveyInfos }
    },
    createSurveyDetails(surveyInfos) {
      this.surveyDetails = { ...surveyInfos }
    },
    resetSurveyDetails() {
      this.surveyDetails = {}
    },
    editSurvey(key, value) {
      this.surveyDetails[key] = value
    },
    createQuestion(type) {
      let question
      switch (type) {
        case 'multiple_choice':
          question = {
            question: "",
            type: 'multiple_choice',
            options: [{
              value: ''
            }],
            multiple_selection: {
              enable: false,
              type: {
                name: 'Must Select',
                value: 'must_select'
              },
              values: []
            },
            randomize: false,
            others: false,
            rule: []
          }
          break
        case 'opinion_scale':
          question = {
            question: "",
            type: 'opinion_scale',
            options: [
              {
                value: '1'
              },
              {
                value: '2'
              },
              {
                value: '3'
              },
              {
                value: '4'
              },
              {
                value: '5'
              },
              {
                value: '6'
              },
              {
                value: '7'
              },
              {
                value: '8'
              },
              {
                value: '9'
              },
              {
                value: '10'
              },
            ],
            labels: {
              start: "",
              mid: "",
              end: ""
            },
            rule: [],

          }
          break
        case 'text':
          question = {
            question: "",
            type: 'text',
            options: [{
              value: ''
            }],
            charecter_counts: {
              min: {
                flag: false,
                value: null
              },
              max: {
                flag: false,
                value: null
              }
            },
          }
          break
        case 'rank':
          question = {
            question: "",
            type: 'rank',
            options: [{
              value: '',
              index: 0,
            }],
            randomize_option: false,
            rule: []
          }
          break
      }
      return question
    },
    addQuestion(type) {
      let question = this.createQuestion(type)
      this.surveyForm.questions.push(question)
    },
    addRule() {
      this.surveyForm.questions[this.activeSurveyFormIndex].rule.push(
        {
          if: {
            is: {
              name: 'is',
              value: 'is',
              option: null,
            },
            and_or: [{
              name: 'and',
              value: 'and',
              option: null,
            }],
          },
          else: "",
          then: {
            go_to: null
          }
        }
      )
    },
    addOpinionRule() {
      this.surveyForm.questions[this.activeSurveyFormIndex].rule.push(
        {
          if: {
            operator: {
              name: '>',
              value: 'greator_than'
            },
            value: ""
          },
          and_or: [],
          else: "",
          then: {
            go_to: null
          }
        }
      )
    },
    addRankingRule() {
      this.surveyForm.questions[this.activeSurveyFormIndex].rule.push(
        {
          if: {
            operator: {
              name: '>',
              value: 'greator_than'
            },
            value: "",
            option: null
          },
          and_or: [],
          else: "",
          then: {
            go_to: null
          }
        }
      )
    },
    addRankOption() {
      this.surveyForm.questions[this.activeSurveyFormIndex].options.push({
        value: '',
        index: this.surveyForm.questions[this.activeSurveyFormIndex].options.length
      })
    },
    removeQuestion(i) {
      this.surveyForm.questions.splice(i, 1)
    },
    updateQuestion(value) {
      this.surveyForm.questions[this.activeSurveyFormIndex].question = value
    },
    addOption() {
      this.surveyForm.questions[this.activeSurveyFormIndex].options.push({
        value: '',
      })
    },
    removeOption(i) {
      this.surveyForm.questions[this.activeSurveyFormIndex].options.splice(i, 1)
    },
    updateOption(value, index) {
      this.surveyForm.questions[this.activeSurveyFormIndex].options[index].value = value
    },
    moveOption(to, from) {
      let drag_ele = this.surveyForm.questions[this.activeSurveyFormIndex].options.splice(from, 1)
      this.surveyForm.questions[this.activeSurveyFormIndex].options.splice(to, 0, drag_ele[0]);
    },
    updateMultipleSelection() {
      this.surveyForm.questions[this.activeSurveyFormIndex].multiple_selection['enable'] = this.surveyForm.questions[this.activeSurveyFormIndex].multiple_selection['enable'] ? true : false
    },
    updateRandomize() {
      this.surveyForm.questions[this.activeSurveyFormIndex].randomize = this.surveyForm.questions[this.activeSurveyFormIndex].randomize ? true : false
    },
    updateEnableOthers() {
      this.surveyForm.questions[this.activeSurveyFormIndex].others = this.surveyForm.questions[this.activeSurveyFormIndex].others ? true : false
    },
    changeQuestionType(type) {
      this.surveyForm.questions[this.activeSurveyFormIndex] = this.createQuestion(type.value)
    },
    addAndOrRule(r) {
      this.surveyForm.questions[this.activeSurveyFormIndex].rule[r].if.and_or.push({
        name: 'and',
        value: 'and',
        option: null,
      })
    },
    addOpinionAndOrRule(r) {
      this.surveyForm.questions[this.activeSurveyFormIndex].rule[r].and_or.push({
        name: 'and',
        value: 'and',
        equation: {
          operator: {
            name: '>',
            value: 'greator_than'
          },
          value: ""
        },
      })
    },
    addRankingAndOrRule(r) {
      this.surveyForm.questions[this.activeSurveyFormIndex].rule[r].and_or.push({
        name: 'and',
        value: 'and',
        equation: {
          operator: {
            name: '>',
            value: 'greator_than'
          },
          value: ""
        },
        option: null
      })
    },
    removeAndOr(r, k) {
      this.surveyForm.questions[this.activeSurveyFormIndex].rule[r].if.and_or.splice(k, 1)
    },
    removeOpinionAndOr(r, k) {
      this.surveyForm.questions[this.activeSurveyFormIndex].rule[r].and_or.splice(k, 1)
    },
    removeRankingAndOr(r, k) {
      this.surveyForm.questions[this.activeSurveyFormIndex].rule[r].and_or.splice(k, 1)
    },
    updateRankingRandomizeStatus(type) {
      this.surveyForm.questions[this.activeSurveyFormIndex].randomize_option = this.surveyForm.questions[this.activeSurveyFormIndex].randomize_option ? true : false
    },
    updateWordStatus(type) {
      this.surveyForm.questions[this.activeSurveyFormIndex].charecter_counts[type].flag = this.surveyForm.questions[this.activeSurveyFormIndex].charecter_counts[type].flag ? true : false
    },
    updateWordCount(type, value) {
      this.surveyForm.questions[this.activeSurveyFormIndex].charecter_counts[type].value = value
    },
    updateLabel(type, value) {
      this.surveyForm.questions[this.activeSurveyFormIndex].labels[type] = value
    }
  }
})

export const useCreatorDashboardSurveysStore = defineStore('homeSurveys', {
  state: () => {
    return {
      availableSurveys: [],
      upcomingSurveys: [],
      loading: false,
      highlight_socials: false,
      instantApprovedApplication: {}
    }
  },
  actions: {
    saveInstantApprovedApplications(info) {
      this.instantApprovedApplication = { ...info }
    },
    saveHomeSurveys(surveys) {
      this.availableSurveys = [...surveys]
    },
    saveUpcomingSurveys(surveys) {
      this.upcomingSurveys = [...surveys]
    },
    startLoader() {
      this.loading = true
    },
    stopLoader() {
      this.loading = false
    },
    highlightSocials() {
      this.highlight_socials = true;
      let vm = this
      setTimeout(() => {
        vm.highlight_socials = false;
      }, 2000);
    }

  }
})

export const useCreatorOnboarding = defineStore('creatorOnboarding', {
  state: () => {
    return {
      creatorsDetails: {},
    }
  },
  actions: {
    saveCreatorInfos(info) {
      this.creatorsDetails = { ...this.creatorsDetails, ...info }
    }
  }
})

export const useResearchApplicants = defineStore('researchApplications', {
  state: () => {
    return {
      researchApplications: [],
      loading: false,
      researchInfo: {},
      loadingResearchInfo: false
    }
  },
  actions: {
    clearAllApplicationInfo() {
      this.researchApplications = [];
    },
    addApplicationInfo(infos) {
      this.researchApplications = infos;
    },
    startLoader() {
      this.loading = true
    },
    endLoader() {
      this.loading = false
    },
    saveResearchInfo(info) {
      this.researchInfo = info;
    },
    startResearchLoader() {
      this.loadingResearchInfo = true
    },
    endResearchLoader() {
      this.loadingResearchInfo = false
    },

  }
})

export const useEarningsStore = defineStore('earnings', {
  state: () => {
    return {
      ledger: [],
    }
  },
  actions: {
    saveEarnings(info) {
      this.ledger = [...info]
    }
  }
})

export const useAdminCreatorStore = defineStore('adminCreator', {
  state: () => {
    return {
      creatorInfo: {},
    }
  },
  actions: {
    saveCreatorInfo(info) {
      this.creatorInfo = { ...this.creatorInfo, ...info }
    },
    clearCreatorInfo() {
      this.creatorInfo = {}
    }
  }
})

export const useAdminOrgStore = defineStore('adminOrg', {
  state: () => {
    return {
      orgInfo: {},
    }
  },
  actions: {
    saveOrgInfo(info) {
      this.orgInfo = { ...info }
    },
    clearOrgInfo() {
      this.orgInfo = {}
    }
  }
})
